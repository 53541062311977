@use "sass:meta";
* {
    box-sizing: border-box;
}

@include meta.load-css("_icons");

#loadingscreen.loaded {
    display: none !important;
}

body {
    background-color: #383530;
    margin: 0;
    overflow: hidden;
    font-size: 2.5vh;
    position: fixed;
    height: 100%;
    width: 100%;
    color: #fbf1d7;
    font-family: 'TF2 Secondary';
}

h1 {
    font-weight: normal;
}

a {
    font-weight: bold;
    color: #5E98D9;
    text-decoration: none;
}

nav {
    height: 5%;
    width: 100%;
    margin: 1vh 0;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.bottom {
        position: absolute;
        bottom: 0;

        .btn {
            max-width: 50%;
            flex-grow: 1;
            flex-basis: 50%;
        }
    }

    &.top {
        .btn {
            height: 100%;
            flex-grow: 1;
            max-width: 50vh;
            flex-basis: 0;
            margin: 0 0.5vh;
        }

        #soundbtn {
            margin: 0 0.5vh;
        }
    }

    .btn,
    .nosoundbtn {
        position: relative;
        margin: 0 1vh;
    }
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #B4B4B4;
    border-radius: 5px;

    &:hover {
        background: #949494;
    }

    &:active {
        background: #646464;
    }
}

::-webkit-scrollbar-button {
    display: none;
}

#aboutchangelog {
    h2 {
        font-family: 'TF2 Build';
        font-size: 3.2vh;
    }

    section {
        font-family: 'TF2 Secondary';
        margin-bottom: 8vh;

        p {
            margin: 0 0 1vh 0;
        }
    }
}

#aboutcredits {
    h1 {
        font-family: 'TF2 Build';
        font-size: 5vh;
        margin: 0 0 5vh 0;
    }

    p {
        font-family: 'TF2 Secondary';
        margin: 1vh 0;
    }
}

.creditsother {
    margin-top: 5vh;
}

#optionsscreen section {
    max-height: 92%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    >div {
        border-bottom: 0.25vh solid #776B5F;
        flex-basis: 50%;
        display: flex;
        flex-direction: column;

        &:nth-of-type(odd) {
            border-right: 0.25vh solid #776B5F;
        }

        &:last-of-type {
            border-bottom: none;
            flex-basis: 100%;
        }

        > {
            .optiontitle {
                font-family: 'TF2 Build';
                text-align: center;
                display: block;
                font-size: 2.5vh;
            }

            p {
                font-size: 2vh;
                text-align: center;
                font-family: 'TF2 Secondary';
                margin-top: 2vh;
                margin-bottom: 3vh;
                width: 100%;
                padding: 0 2%;

                &:last-child {
                    margin-top: auto;
                }
            }
        }
    }
}

.btngroup {
    height: 5vh;
    padding: 0 1vh;
    margin-bottom: 2vh;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    font-family: "TF2 Build";

    &.checkboxgroup {
        height: auto;
        display: grid;
        grid-auto-rows: 6vh;
        grid-template-columns: 6vh auto;
        grid-template-rows: 6vh;
        align-items: center;
        justify-content: center;
        font-size: 2.2vh;

        &.col2 {
            grid-template-columns: 6vh auto 6vh auto;
        }

        .checkbox {
            height: 4.5vh;
            width: 4.5vh;
            border: 0.25vh solid #776B5F;
            background-color: #211e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0;
            margin-right: 1vh;
            cursor: pointer;

            &.checked {
                font-size: 3vh;
            }
        }

        p {
            margin: 0 2vh 0 0;
            text-align: left;
        }

        select {
            margin-left: 1vh;
            height: 4.5vh;
        }

        span {
            display: flex;
            align-items: center;
        }
    }

    >.btn {
        position: static;
        flex-basis: 25%;

        &#resetbtn {
            flex-basis: 33%;
        }
    }

    select {
        left: 15%;
        width: 70%;
        font-family: 'TF2 Build';
        font-size: 2vh;
        border-radius: 1.5vh;
        background-color: #776B5F;
        border-color: #776B5F;
        border-style: solid;
        text-align: center;
        text-align-last: center;
        color: #fbf1d7;
    }
}

#crateselectscreen,
#countdownscreen,
#resultscreen,
#statsscreen,
#aboutscreen,
#optionsscreen,
#bulkunboxselect {
    height: 100%;
    width: 100%;
}

#countdownscreen,
#bulkunboxselect {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #0005;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

#aboutscreen {
    text-align: center;
}

#countdown,
#bulkunboxcontainer,
#bulkunboxprogress {
    background-color: #292526;
    border: 0.5vh solid #554D4A;
    border-radius: 2.5vh;
    position: absolute;
    min-width: 45vh;
    max-width: 90vw;
    height: 30vh;
    left: 50%;
    transform: translateX(-50%);
    top: 35vh;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #B85345;

    #uncratingtext {
        margin-bottom: 2vh;
    }
}

#bulkunboxcontainer,
#bulkunboxprogress {
    height: auto;
    padding: 1vh;
    color: #fbf1d7;

    input {
        margin-bottom: 2vh;
        padding: 0.75vh 0;
        font-size: 2.5vh;
        background-color: #211e1e;
        color: #fbf1d7;
        border-color: #776B5F;
        border-style: solid;
        font-family: "TF2 Build";
        text-align: center;
    }

    .btn {
        height: 5vh;
        flex-grow: 1;
        flex-basis: 0;
    }

    progress {
        width: 100%;
        height: 3vh;
        border: 2px solid #776B5F;
        border-radius: 2.5vh;
        -webkit-appearance: none;
        overflow: hidden;

        &::-webkit-progress-bar {
            border-radius: 2.5vh;
            background-color: #211e1e;
        }

        &::-webkit-progress-value {
            background-color: #fbf1d7;
        }
    }
}

#resultscreen nav {
    position: absolute;
    bottom: 0;
}

[data-tooltip] {
    position: relative;

    &::after {
        content: attr(data-tooltip);
        display: none;
        position: absolute;
        font-family: 'TF2 Build';
        font-size: 2.5vh;
        text-align: center;
        border-radius: 0.5vh;
        background-image: linear-gradient(to bottom, #534a42, #373128);
        padding: 1vh 2vh;
        color: #fbf1d7;
        font-weight: normal;
        white-space: nowrap;
        pointer-events: none;

        /* Center button text horizontally and vertically */
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 100;
    }

    &.tooltiptopleft::after {
        bottom: 90%;
        right: 90%;
    }

    &.tooltiptop::after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &.tooltiptopright::after {
        bottom: 90%;
        left: 90%;
    }

    &.tooltipleft::after {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
    }

    &.tooltipbottomleft::after {
        top: 90%;
        right: 90%;
    }

    &.tooltipbottomright::after {
        top: 90%;
        left: 90%;
    }

    &:hover::after {
        display: flex;
    }
}

/* Button style */

.btn,
.nosoundbtn,
.btndisabled {
    font-family: 'TF2 Build';
    font-size: 2.2vh;
    text-align: center;
    border-radius: 1.5vh;
    background-color: #776B5F;
    position: absolute;
    cursor: pointer;

    /* Center button text horizontally and vertically */
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

a.btn {
    color: #fbf1d7;
    text-decoration: none;
    font-weight: normal;
}

.btn i,
.nosoundbtn i,
.btndisabled i {
    font-size: 3.8vh;

    &.icon-backpacktf {
        font-size: 3vh;
    }
}

.btn [data-string] {
    display: none;
}

[data-string="160"] {
    white-space: nowrap;
}

.navbtn {
    width: 5vh;
    height: 40%;
    top: 30%;
    font-size: 8vh;
    z-index: 1;
}

#cratewindow>.btn {
    height: 8vh;
    width: 5vh;
    right: -2.75vh;
    z-index: 2;
}

#gridviewbtn {
    top: 6%;

    >i {
        font-size: 6vh;
    }
}

#randomcratebtn {
    top: 18%;
}
#searchbtn {
    top: 30%;
}

#previouscratebtn {
    left: -2.75vh;
}

#nextcratebtn {
    right: -2.75vh;
}

.btn:hover,
.nosoundbtn:hover {
    background-color: #91493B;
}

.btn:active,
.nosoundbtn:active {
    background-color: #81392B;
}

#unboxbtn,
#randomcratebtn {
    height: 100%;
    flex-grow: 1;
}

.btndisabled {
    background-color: #534a42;
    color: #222222;

    &:hover,
    &:active {
        background-color: #534a42;
        cursor: initial;
    }

    i {
        color: #222222;
    }
}

#steambtn,
#soundbtn {
    width: 8vh;
    height: 100%;
    color: #fbf1d7;
}

#crateimagediv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

#crateinfotext {
    font-family: 'TF2 Secondary';
    text-align: center;
}

#countdown>p {
    font-family: 'TF2 Build';
    font-size: 3vh;
    margin: 0;

    &:last-child {
        font-size: 6vh;
    }
}

.tooltip {
    height: 6vh;
    display: none;
    position: absolute;
    font-family: 'TF2 Build';
    font-size: 2.5vh;
    text-align: center;
    border-radius: 0.5vh;
    background-image: linear-gradient(to bottom, #534a42, #373128);
    padding: 0 2vh;

    /* Center button text horizontally and vertically */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
}

#steamtooltip {
    top: 90%;
    left: 7vh;
}

#soundtooltip {
    top: 90%;
    right: 7vh;
}

#steama:hover+.tooltip,
#soundbtn:hover+.tooltip {
    display: flex;
}

#crateimagediv img {
    max-width: 100vw;
    max-height: 70%;
    height: 70%;
    object-fit: contain;
}

#crategridsearch {
    display: none;
    height: 6%;
    flex-wrap: none;
    border-bottom: 0.5vh solid #554D4A;
    .crategridsearchicon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 0.5vh solid #554D4A;
        width: 6vh;
        .icon-search {
            font-size: 2.8vh;
        }
    }
}
#crategridsearchinput {
    background: none;
    border: none;
    flex-grow: 1;
    flex-basis: 10%;
    color: #fbf1d7;
    font-family: "TF2 Build";
    font-size: 2.5vh;
    padding: 0 1vh;
}

#cratewindow {
    background-color: #292526;
    border: 0.5vh solid #554D4A;
    border-radius: 2.5vh;
    position: absolute;
    width: calc(100% - 6vh);
    left: 3vh;
    top: 8%;
    height: 84%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.showsearch.gridactive {
        #crategrid {
            flex-basis: 93%;
        }
        #crategridsearch {
            display: flex;
        }
    }
}

#crateview {
    position: relative;
    width: 100%;
    height: 100%;
}

#crategrid {
    height: 100%;
    padding: 1vh 0.5vh 0 1vh;
    grid-auto-rows: 32.5%;
    grid-template-columns: repeat(auto-fill, minmax(22vh, 1fr));
    grid-template-rows: 32.5%;
    grid-gap: 1% 1%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 7vh;
}

#cratemain {
    height: 100%;
    float: left;
    width: 49%;
    position: relative;
}

#crateinfo {
    height: 100%;
    width: 100%;
}

#cratedetails {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    -webkit-touch-callout: none;
}

#cratename {
    color: #FFD700;
    font-family: "TF2 Build";
    text-align: center;
    font-size: 3vh;
    margin: 0;
    padding: 1vh 0.5vh 0 0.5vh;
}

#crateseries {
    color: #FFD700;
    font-family: "TF2 Build";
    text-align: center;
    font-size: 1.8vh;
    margin: 0;
}

#crateresult>p {
    margin: 0;
    margin-top: 1vh;
}

#newlootacquired {
    text-align: center;
    line-height: 4vh;
    font-family: 'TF2 Build';
    font-size: 4.2vh;
    overflow: hidden;
    margin: 1vh;
}

#youtext {
    font-family: 'TF2 Build';
    font-size: 2.75vh;
    text-align: center;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    margin: 0;
}

#unboxedtext {
    color: #B85345;
}

#unboxeditem {
    background-color: #332F2E;
    border: 0.5vh solid #554D4A;
    border-radius: 2.5vh;
    position: absolute;
    height: 80%;
    bottom: 7%;
    width: 85vw;
    left: 7.5vw;
}

#crateinfobtn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 1%;
    width: calc(100% - 22vh);
    height: 5vh;
    display: flex;
}

#crateinforeturnbtn {
    left: 7.5%;
    bottom: 1%;
    width: 40%;
    height: 5vh;
}

#crateinfoeffectsbtn,
#crateinfolootbtn {
    right: 7.5%;
    bottom: 1%;
    width: 40%;
    height: 5vh;
}

.tabreturnbtn {
    width: 98%;
    height: 5%;
    bottom: 0;
    left: 1%;
}

#returnbtn {
    left: 1%;
    width: 47%;
}

#unboxagainbtn {
    right: 1%;
    width: 47%;
}

#lootimgdiv {
    height: 100%;
    position: relative;
}

#lootimg,
#effectimg {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#effectimg {
    transform: translate(-50%, -65%);
}

#kitimg {
    position: absolute;
    top: 37%;
    left: 50%;
    height: 16% !important;
    width: auto !important;
    transform: translateX(-65%);
    z-index: 1;
}

.kitimg {
    position: absolute;
    top: 43%;
    left: 50%;
    height: 32% !important;
    width: auto !important;
    transform: translateX(-63%);
    z-index: 1;
}

#kitfield {
    text-align: center;
    font-family: "TF2 Secondary";
    font-size: 2.4vh;
}

#lootimg {
    z-index: 1;
}

#lootimgdiv img {
    display: block;
    height: 50%;
    width: 100%;
    object-fit: contain;
}

#loottextdiv {
    height: 100%;
    width: 98%;
    position: absolute;
    top: 0;
    left: 1%;
}

#lootname {
    display: block;
}

#lootnamediv {
    font-family: "TF2 Build";
    text-align: center;
    font-size: 3vh;
    position: relative;
    top: 3%;
    width: 95%;
    margin: 0 auto;
}

#lootbonusandstats {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 1%;
    display: flex;
    height: 35%;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 11;

    >div {
        height: 100%;
    }

    #lootbonusitems {
        height: 50%;
        flex-grow: 1;

        #lootbonusitemheader {
            >p {
                margin: 0;
            }

            height: 25%;
            font-family: 'TF2 Secondary';
            font-size: 2.75vh;
            text-align: center;
        }

        #lootbonusnone {
            display: flex;
            justify-content: center;
            font-family: 'TF2 Build';
            font-size: 3.5vh;
        }
    }

    #lootstatscontainer {
        flex-direction: row;
        height: 15%;
    }
}

#lootmarketbtns {
    position: absolute;
    top: 40%;
    right: 0.5vh;
    transform: translateY(-50%);
    z-index: 10;

    a {
        height: 6vh;
        width: 6vh;
        position: relative;
        margin: 1vh 0;

        &:first-child {
            margin-top: 0;
        }
    }

    [data-tooltip]::after {
        min-width: 20vh;
        white-space: normal;
    }
}

#lootbonusitems {
    text-align: center;
    font-family: "TF2 Build";
    font-size: 3vh;
    line-height: 3vh;

    #lootbonusitemcontainer {
        display: flex;
        justify-content: space-evenly;
        height: 70%;

        >div {
            border: 0.2vh solid #FFD700;
            background-color: #292526;
            border-radius: 2.5vh;
            max-height: 15vh;
            width: 15vh;
            max-width: 33vw;
            display: inline-block;
            position: relative;

            &.unusual {
                border-color: #8650ac;
            }

            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;

                &.unusualifierimg {
                    position: absolute;
                    height: 39%;
                    top: 49%;
                    left: 35%;
                }
            }
        }
    }
}

.bonustooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
    background-image: linear-gradient(to bottom, #534a42, #373128);
    padding: 1vh 4vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.2vh;
    line-height: 2.5vh;
    border-radius: 0.5vh;
    color: #FFD700;
    z-index: 1;
    max-width: 100vw;
}

#lootbonusitemcontainer div:hover .bonustooltip {
    display: block;
}

#lootstatscontainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    div {
        font-family: "TF2 Build";
        font-size: 4.25vh;
        text-align: center;

        [data-string] {
            font-family: 'TF2 Secondary';
            font-size: 2.25vh;
            line-height: 3vh;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

#statscontainer>div,
#aboutcontainer>div {
    display: none;
    overflow-y: auto;
    height: 100%;
    padding: 0 1vh;
}

div.visible {
    display: block !important;
}

#statscontainer,
#aboutcontainer {
    height: 86%;
    font-family: 'TF2 Secondary';
}

#aboutdonations {
    font-size: 2vh;
    line-height: 2.8vh;

    h2,
    h3 {
        font-size: 2.75vh;
    }

    ol {
        margin-top: 0;
        font-size: 2.5vh;
        display: inline-block;
        list-style-position: inside;
        padding: 0;

        li {
            margin: 0.5vh 0;
        }
    }
}

#aboutchangelog {
    font-size: 2vh;
}

#statscontainerinner {
    >nav {
        height: auto;
        font-family: "TF2 Build";
        width: 60%;
        margin: 0 auto;

        > {
            div {
                display: flex;
                align-items: center;
            }

            .btn {
                width: 15vh;
                font-size: 4.5vh;
                margin: 0;
            }
        }
    }

    >div {
        #statsluckcontainer {
            white-space: normal;

            p {
                margin-top: 0;
                font-size: 2.5vh;
            }
        }

        height: 100%;
        width: 100%;
        padding: 0 1vh;
        display: none;
        flex-direction: column;
        align-items: center;
        font-size: 2.5vh;
        overflow: auto;

        &.statvisible {
            display: flex !important;
        }
    }

    table {
        white-space: normal;
        border-spacing: 0 1vh;

        td {
            padding-right: 1vh;
        }

        td:first-child {
            text-align: left;
        }
    }

    text-align: center;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    flex-direction: column;

    &.visible {
        display: flex !important;
    }

    #unusualluck,
    #strangeluck {
        font-size: 3.2vh !important;
        font-family: "TF2 Build";

        &.lucky {
            color: #22cb22;
        }

        &.unlucky {
            color: #cb2222;
        }

        &.neither {
            color: #FFD700;
        }
    }

    #unusualluckdesc,
    #strangeluckdesc {
        line-height: 3vh;

        &#unusualluckdesc {
            margin-bottom: 5vh;
        }

        &#strangeluckdesc {
            margin-bottom: 0;
        }
    }

    >div> {
        div {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

#statsluckcontainer {
    display: none;
    flex-direction: column;
}

#statslucknotenough {
    display: none;
    font-size: 3.2vh;
    white-space: normal;
}

#nounusuals,
#noitems {
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    font-size: calc(3.5vh + 0.75vw);
    margin: 0;
    font-family: 'TF2 Secondary';
}

#statscrates {
    height: 100%;
    padding: 0 0.5vw;
    grid-auto-rows: 32.5%;
    grid-template-columns: repeat(auto-fill, minmax(22vh, 1fr));
    grid-template-rows: 32.5%;
    grid-gap: 1% 1%;
    overflow: hidden auto;

    &.visible {
        display: grid !important;
    }
}

#statsunusualscontent,
#bulkresultsunusualscontent {
    height: 100%;
    padding: 0 0.5vw;
    grid-auto-rows: 49%;
    grid-template-columns: repeat(auto-fill, minmax(25vh, 1fr));
    grid-template-rows: 49%;
    grid-gap: 1% 1%;
    display: grid;

    >div {
        background-color: #292526;
        border: 0.2vh solid #8650ac;
        border-radius: 2.5vh;
        font-size: 2vh;
        text-align: center;
        position: relative;
    }
}

#statscratesdetails {
    height: 100%;
    display: none;
    flex-direction: column;

    &.visible {
        display: flex;
    }

    >div {
        height: 100%;
        overflow: hidden auto;
        display: flex;
        flex-direction: column;
    }

    #cratedetailsheader {
        height: 15%;
        justify-content: space-between;
        position: relative;
        display: flex;
        border-bottom: 0.5vh solid #615C59;
        background: repeating-linear-gradient(-60deg, #282320, #282320 0.6vh, #2e2926 0.6vh, #2e2926 1.8vh);
        text-align: center;
        color: #FFD700;
        font-family: "TF2 Build";
        margin-bottom: 1vh;

        >img {
            height: 100%;
            margin: 0 1vh;
            flex-basis: 30%;
            object-fit: contain;
            object-position: left;
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 1vh;
            flex-basis: 40%;

            >p {
                margin: 0;
            }

            &#cratedetailsstats {
                flex-basis: 30%;
            }
        }
    }

    nav .btn {
        width: 98%;
        left: 1%;
        margin: 0;
    }
}

#statscratesdetailscontent,
#bulkresultsitems,
#bulkresultsbonusitems {
    display: grid;
    padding: 0 0.5vw;
    grid-auto-rows: 42%;
    grid-template-columns: repeat(auto-fill, minmax(23vh, 1fr));
    grid-template-rows: 42%;
    grid-gap: 1vh 1vh;
    flex-grow: 1;
    max-height: 83%;

    &.col1 {
        grid-auto-rows: 48%;
        grid-template-rows: 48%;
    }

    &.col2 {
        grid-template-columns: repeat(auto-fill, minmax(45vh, 1fr));
    }

    &.col3 {
        grid-template-columns: repeat(auto-fill, minmax(61vh, 1fr));
        grid-auto-rows: 52%;
        grid-template-rows: 52%;
    }

    color: #FFD700;
    border-color: #FFD700;

    >div {
        background-color: #292526;
        border: 0.2vh solid;
        border-radius: 2.5vh;
        font-size: 2vh;
        text-align: center;
        position: relative;

        >div:not(.cratedetailsitembottom) {
            position: relative;
            top: 5%;
            height: 90%;
            display: flex;

            >img {
                height: 90%;
                max-width: 45%;
                object-fit: contain;
                position: relative;
                top: 5%;
                flex-grow: 1;
            }

            .cratedetailsqualitylist {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;

                &:nth-child(3) {
                    margin-left: 0.5vh;
                }

                >p {
                    font-family: "TF2 Build";
                    font-size: 1.9vh;
                    margin: 0.25vh 0;

                    &:nth-child(even) {
                        margin-bottom: 0.75vh;
                    }
                }
            }
        }

        .cratedetailsitembottom {
            position: absolute;
            bottom: 1%;
            width: 100%;

            .cratedetailsitemmarketbtns {
                width: 100%;
                display: flex;
                justify-content: space-evenly;

                a {
                    width: 30%;
                    position: relative;
                    height: 4vh;

                    i {
                        font-size: 3vh;
                    }
                }

                [data-tooltip]::after {
                    min-width: 20vh;
                    white-space: normal;
                }
            }
        }

        >p {
            font-family: "TF2 Build";
            padding: 0 1vw;
            z-index: 1;
            position: absolute;
            width: 100%;
            text-shadow: 0.1vh 0.1vh black;
            margin: 0;
        }

        .cratedetailsqualitylist {
            color: #fbf1d7;
        }

        .cratedetailsitemunboxed {
            margin: 0 0 0.5vh;
            font-family: "TF2 Build";
            &.cratedetailsitemnomarket {
                margin: 0;
                bottom: 0;
            }
        }

        .cratedetailskillstreak {
            font-family: "TF2 Secondary";
            color: #7ea9d1;
            font-size: 1.8vh;
        }
    }
}

#statscrates>div,
#crategrid div {
    background-color: #292526;
    border: 0.2vh solid #FFD700;
    border-radius: 2.5vh;
    font-size: 2vh;
    text-align: center;
    position: relative;
    user-select: none;

    &:last-child {
        margin-bottom: 1px;
    }
}

#statsunusualscontent>div,
#bulkresultsunusualscontent>div {
    p {
        font-family: "TF2 Build";
        color: #8650ac;
        padding: 0 1vw;
        z-index: 1;
        width: 100%;
    }

    >p {
        margin-top: 1vh;
    }
}

.statsunusualsbottom {
    position: absolute;
    bottom: 1%;
    width: 100%;
}

.statsunusualsmarketbtns {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    a {
        width: 30%;
        position: relative;
        height: 4vh;

        i {
            font-size: 3vh;
        }
    }

    [data-tooltip]::after {
        min-width: 20vh;
        white-space: normal;
    }
}

#statscrates>div:hover,
#crategrid div:hover {
    background-color: #927600 !important;
}

.statscratestext {
    height: 30%;
}

#statsunusualscontent>div>img,
#bulkresultsunusualscontent>div>img,
#statscratesdetailscontent>div>img,
#bulkresultsitems>div>img,
#bulkresultsbonusitems>div>img {
    top: 25%;
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 55%;
    left: 0;
    z-index: 0;

    &.cratedetailskitimg {
        top: 50%;
        height: 15%;
        width: auto;
        left: 50%;
        transform: translateX(-66%);
    }

    &.unusualifierimg {
        height: 24%;
        width: auto;
        top: 51%;
        left: 50%;
        transform: translateX(-40%);
    }

    &.effectimg {
        top: 20%;
        height: 60%;
    }
}

#statscrates>div>img,
#crategrid div img {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 90%;
    top: 5%;
    left: 0;
    z-index: 0;
}

.statscratesname,
.statscratesseries,
.statsitemname,
.statsitemunbox {
    font-family: "TF2 Build";
    padding: 0 1vw;
    z-index: 1;
    position: absolute;
    width: 100%;
    text-shadow: 0.1vh 0.1vh black;
}

.statscratesname,
.statsitemname {
    color: #FFD700;
    margin-top: 0.5vh;
}

.statscratesseries,
.statsitemunbox {
    bottom: 0;
    margin-bottom: 0.5vh;
    color: #FFD700;
}

.statsunusualsname {
    margin-top: 1vh;
}

.statsunusualseffect {
    bottom: 0;
    margin-bottom: 1vh;
}

.break {
    display: none;
}

.active {
    background-color: #bD4A37 !important;
}

#cratelootdiv,
#crateeffectsdiv {
    max-height: 80%;
    overflow: auto;
    text-align: center;
    font-family: "TF2 Secondary";
    font-size: 2vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

#cratelootlist,
#crateeffectslist,
#cratelootbonus ul {
    list-style: none;
    padding-left: 0;
    line-height: 3vh;
}

#cratelootlist {
    color: #8f8a83;
}

#crateeffectslist,
#cratelootbonus ul {
    color: #FFD700;
}

#cratenote {
    margin-bottom: 0;
}

.column {
    column-count: 2;
}

#crateloottitle {
    margin-top: 0;
}

#effectname,
#effecttext {
    text-align: center;
    font-family: "TF2 Secondary";
    font-size: 2.4vh;
    color: #8650AC;
}

.unusualloot,
#kitfield {
    color: #7ea9d1;
}

#exitgridviewbtn {
    top: 6%;
}
#statsunusuals {
    overflow-x: hidden;
}
#statsunusuals nav {
    height: 5vh;
    max-width: 80vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    div {
        text-align: center;
        margin: 0 2vh;
    }

    .btn {
        height: 100%;
        max-width: 20vh;
        flex-grow: 1;
        font-size: 4vh;
        margin: 0 1vh;
    }
}

#bulkunboxresults {
    height: 100%;
    width: 100%;

    >div {
        height: 93%;
        width: 100%;
        overflow: hidden auto;
        display: flex;
        flex-direction: column;

        nav {
            height: 5vh;
            min-height: 5vh;
            max-width: 80vh;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            div {
                text-align: center;
                margin: 0 2vh;
            }

            .btn {
                height: 100%;
                max-width: 20vh;
                flex-grow: 1;
                font-size: 4vh;
                margin: 0 1vh;
            }
        }
    }

    [data-statstring] {
        display: none;
        padding: 0 1vh;

        &.bulkvisible {
            display: flex;

            &#bulkresultsitems,
            &#bulkresultsbonusitems {
                display: grid;
            }
        }
    }

    #bulkresultsunusuals {
        flex-direction: column;
        height: 100%;
    }

    >nav {
        margin-top: 1vh;
        justify-content: center;

        >.btn {
            width: 100%;
            max-width: 66vh;
        }
    }
}

#bulkresultsstats {
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
        margin: 1vh 0;
    }
}

#bulkresultsheader {
    border-bottom: 0.5vh solid #615C59;
    background: repeating-linear-gradient(-60deg, #282320, #282320 0.6vh, #2e2926 0.6vh, #2e2926 1.8vh);
    text-align: center;
    color: #FFD700;
    font-family: "TF2 Build";
    text-align: center;
    padding: 1vh 0;

    p {
        margin: 0;
    }

    #bulkresultstitle {
        margin-top: 2vh;
    }
}

[data-unusualmaxpage="1"],
[data-unusualmaxpage="0"] {
    display: none;
}

@media (orientation: portrait) {
    @media (max-aspect-ratio: 6 / 10) {
        #lootstatscontainer {
            height: 40% !important;

            div span {
                display: block;
            }
        }

        .btngroup.col2 {
            grid-template-columns: 6vh auto !important;

            [data-option]:nth-of-type(even),
            [data-optionvalue]:nth-of-type(even) {
                order: 1;

                +p,
                +span {
                    order: 1;
                }
            }
        }
    }

    @media (max-aspect-ratio: 7 / 10) {

        #statscratesdetailscontent,
        #bulkresultsitems,
        #bulkresultsbonusitems {

            &.col2 {
                grid-template-columns: 100% !important;
            }

            &.col3 {
                grid-template-columns: 100% !important;
                grid-auto-rows: 60% !important;
                grid-template-rows: 60% !important;
            }

            &#bulkresultsitems {
                &.col3 {
                    grid-auto-rows: 63% !important;
                    grid-template-rows: 63% !important;
                }
            }
        }

        [data-tooltip] {
            &.tooltipmobiletopleft::after {
                top: initial;
                right: initial;
                bottom: 90%;
                right: 90%;
                transform: none;
            }

            &.tooltipmobiletopright::after {
                top: initial;
                right: initial;
                bottom: 90%;
                left: 90%;
                transform: none;
            }

            &.tooltipmobiletop::after {
                top: initial;
                right: initial;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            &.tooltipmobileleft::after {
                top: 50%;
                right: 100%;
                transform: translateY(-50%);
            }

            &.tooltipmobileright::after {
                top: 50%;
                left: 100%;
                right: initial;
                transform: translateY(-50%);
            }

            &.tooltipmobilebottomleft::after {
                top: 90%;
                right: 90%;
            }

            &.tooltipmobilebottomright::after {
                top: 90%;
                left: 90%;
            }
        }

        .gridactive [data-tooltip].tooltipmobilegridtop::after {
            top: initial;
            right: initial;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        #crateselectscreen nav.top {

            .btn,
            #soundbtn {
                margin: 0 0.25vh;
            }
        }

        #optionsscreen>div>span {
            width: 100%;
            height: initial;
            margin-bottom: 1vh;
        }

        #cratelootdiv,
        #crateeffectsdiv {
            padding: 0 1vh;
            max-height: 71%;
            width: 100% !important;
            left: 0 !important;
            top: 48% !important;
        }

        #cratedetails {
            width: 100% !important;
            left: 0 !important;
        }

        #crateinforeturnbtn {
            left: 11vh;
            bottom: 1%;
            width: calc(50% - 12vh);
            height: 5vh;
        }

        #crateinfoeffectsbtn,
        #crateinfolootbtn {
            right: 11vh;
            bottom: 1%;
            width: calc(50% - 12vh);
            height: 5vh;
        }

        #cratewindow:not(.gridactive) {
            #gridviewbtn {
                top: initial;
                bottom: 1%;
                left: 1vh;
                right: initial;
                height: 5vh;
                width: 8vh;
            }

            #randomcratebtn {
                top: initial;
                bottom: 1%;
                left: initial;
                right: 1vh;
                height: 5vh;
                width: 8vh;
            }

            #searchbtn {
                top: initial;
                bottom: 1%;
                left: initial;
                right: 1vh;
                height: 5vh;
                width: 8vh;
            }

            #previouscratebtn {
                top: initial;
                bottom: 7vh;
                left: 1vh;
                height: 5vh;
                width: 45%;
                font-size: 6vh;
            }

            #nextcratebtn {
                top: initial;
                bottom: 7vh;
                right: 1vh;
                height: 5vh;
                width: 45%;
                font-size: 6vh;
            }
        }

        #cratewindow.gridactive {
            padding-bottom: 6.5vh;

            >.btn {
                height: 5vh;
                width: 30%;
            }

            #exitgridviewbtn {
                top: initial;
                bottom: 0.75vh;
                left: 5%;
                width: 26.66%;
            }

            #randomcratebtn {
                top: initial;
                bottom: 0.75vh;
                left: 36.66%;
                width: 26.66%;
            }

            #searchbtn {
                top: initial;
                bottom: 0.75vh;
                right: 5%;
                width: 26.66%;
            }
        }

        #crategrid {
            padding: 0 1vh 1vh;
            margin-right: 0;
        }

        .btngroup {
            >.btn {
                flex-basis: 35%;
            }

            select {
                width: 90%;
                border-style: solid;
            }
        }
    }

    @media (max-aspect-ratio: 9 / 10) {

        #unboxeditem {
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            width: 100%;
            padding: 0 1vh;
            left: 0;
        }
    }

    #optionsscreen section>div {
        flex-basis: 100%;
        border-right: none !important;

        &:nth-last-of-type(2) {
            border-bottom: 0.25vh solid #776B5F;
        }
    }

    #cratedetailsheader {
        height: auto !important;
        flex-direction: column;
        padding: 0 1vh;

        >img {
            display: none;
        }

        >div {
            margin: 1vh 0 !important;
        }
    }

    #cratewindow {
        width: 100%;
        left: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        top: 7%;
        height: 86%;

        >.btn {
            right: 0.5vh;
        }
    }

    #cratedetails {
        width: calc(100% - 8vh);
        left: 4vh;
    }

    #previouscratebtn {
        left: 0.5vh;
    }

    #nextcratebtn {
        right: 0.5vh;
    }

    #cratelootdiv,
    #crateeffectsdiv {
        left: 3.5vh;
        width: calc(100% - 7vh);
    }

    #statscontainerinner>nav {
        width: 98%;
    }

    .break {
        display: initial;
    }

    #cratemain {
        width: 100%;
    }

    #lootunboxchance {
        left: 5%;
    }

    #lootunboxedtimes {
        right: 5%;
    }

    #lootbonusitemcontainer {
        .bonustooltip {
            min-width: 33vw;
        }

        &.i2 {
            .bonustooltip {
                min-width: 40vw;
            }
        }
    }
}

@media (orientation: landscape) {
    #lootstatscontainer>div {
        flex-basis: 50%;
    }

    #crategrid {
        margin-right: 3.5vh;
    }

    .btn [data-string] {
        display: inline;
    }

    nav .btn i {
        position: absolute;
        left: 1.5vh;
    }

    #steambtn i,
    #soundbtn i {
        position: static;
    }

    #cratelootdiv,
    #crateeffectsdiv {
        left: 3.5vh;
        width: calc(100% - 7vh);
    }

    #randomcratebtn {
        width: 50%;
    }

    #cratemain {
        width: 100%;
    }

    .tabreturnbtn,
    #statscratesdetails nav .btn {
        width: 60%;
        left: 20%;
    }

    #returnbtn {
        left: 5%;
        width: 40%;
    }

    #unboxagainbtn {
        right: 5%;
        width: 40%;
    }

    #lootunboxchance {
        left: 5%;
    }

    #lootunboxedtimes {
        right: 5%;
    }

    @media (min-aspect-ratio: 4 / 3) {


        #lootunboxchance,
        #lootunboxedtimes {
            width: 25%;
            bottom: 17.5%;
        }

        #lootunboxchance {
            left: initial;
        }

        #lootunboxedtimes {
            right: 0;
        }

        #lootmarketbtns {
            top: 45%;
        }

        #cratemain {
            width: 49%;
            display: block !important;
        }

        #crateinfobtn,
        #crateinforeturnbtn {
            display: none !important;
        }

        #lootimgdiv {
            width: 50%;
            float: left;

            img {
                height: 60%;
            }

            #effectimg {
                height: 70%;
            }
        }

        #lootimg,
        #effectimg {
            top: 50%;
        }

        #crateimagediv img {
            max-height: 90%;
        }

        #unboxeditem {
            width: 80%;
            left: 10%;
            height: 77%;
            bottom: 10%;
        }

        #loottextdiv {
            width: 50%;
            left: initial;
            right: 0%;
        }

        #lootnamediv {
            top: 10%;
        }

        #crateinfoeffectsbtn,
        #crateinfolootbtn {
            left: 15%;
            width: 70%;
        }

        #crateimagediv {
            width: 50%;
            left: 0;
            display: flex !important;
        }

        #cratedetails {
            width: 50%;
            right: 0;
            display: block !important;
        }

        #lootbonusandstats {
            height: 42%;
        }

        #lootstatscontainer {
            height: 28% !important;

            div span {
                display: block;
            }
        }

        #kitimg {
            top: 47%;
            left: 50%;
            height: 17% !important;
            transform: translateX(-68%);
        }
    }

    @media (min-aspect-ratio: 14 / 9) {
        #optionsscreen {
            width: 155vh;
            margin: 0 auto;
        }
    }
}

// Amoled theme
.amoledtheme {
    background-color: #000;

    #cratewindow,
    #crategrid div,
    #statsunusualscontent>div,
    #statscrates>div,
    #unboxeditem,
    #lootbonusitems #lootbonusitemcontainer>div,
    #countdown,
    #bulkunboxselect>div,
    #bulkresultsunusualscontent>div,
    #bulkresultsbonusitems>div,
    #bulkresultsitems>div {
        background-color: #000;
    }

    .checkbox {
        background-color: #000 !important;
    }

    #cratedetailsheader,
    #statscratesdetailscontent>div,
    #bulkresultsheader {
        background-color: #000 !important;
        background-image: none !important;
    }
}

// Item quality colors
.colorunique {
    color: #FFD700;

    &.bordercolor {
        border-color: #FFD700;
    }
}

.colorstrange {
    color: #CF6A32;

    >.statsitemname {
        color: #CF6A32;
    }

    &.bordercolor {
        border-color: #CF6A32;
    }
}

.colorhaunted {
    color: #38F3AB;

    >.statsitemname {
        color: #38F3AB;
    }

    &.bordercolor {
        border-color: #38F3AB;
    }
}

.colorcivilian {
    color: #B0C3D9;

    &.bordercolor {
        border-color: #B0C3D9;
    }
}

.colorfreelance {
    color: #5E98D9;

    &.bordercolor {
        border-color: #5E98D9;
    }
}

.colormercenary {
    color: #4B69FF;

    &.bordercolor {
        border-color: #4B69FF;
    }
}

.colorcommando {
    color: #8847FF;

    &.bordercolor {
        border-color: #8847FF;
    }
}

.colorassassin {
    color: #D32CE6;

    &.bordercolor {
        border-color: #D32CE6;
    }
}

.colorelite {
    color: #EB4B4B;

    &.bordercolor {
        border-color: #EB4B4B;
    }
}

.colorunusual {
    color: #8650AC;

    &.bordercolor {
        border-color: #8650AC;
    }
}

.colordecorated {
    color: #FAFAFA;
}