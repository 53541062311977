@font-face {
  font-family: 'icons';
  src:
    url('../font/icons.ttf?jqgza0') format('truetype'),
    url('../font/icons.woff?jqgza0') format('woff'),
    url('../font/icons.svg?jqgza0#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e90c";
}
.icon-info:before {
  content: "\e904";
}
.icon-volume-mute:before {
  content: "\e90a";
}
.icon-dice:before {
  content: "\e905";
}
.icon-backpacktf:before {
  content: "\e908";
}
.icon-marketplacetf:before {
  content: "\e909";
}
.icon-options:before {
  content: "\e900";
}
.icon-speaker:before {
  content: "\e902";
}
.icon-mute:before {
  content: "\e901";
}
.icon-steam:before {
  content: "\e903";
}
.icon-grid:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e90d";
}
.icon-list:before {
  content: "\e90b";
}
.icon-chart:before {
  content: "\e907";
}
