* {
  box-sizing: border-box;
}

@font-face {
  font-family: icons;
  src: url("icons.0f729329.ttf") format("truetype"), url("icons.7a70180b.woff") format("woff"), url("icons.da0ec08c.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icons !important;
}

.icon-checkmark:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-volume-mute:before {
  content: "";
}

.icon-dice:before {
  content: "";
}

.icon-backpacktf:before {
  content: "";
}

.icon-marketplacetf:before {
  content: "";
}

.icon-options:before {
  content: "";
}

.icon-speaker:before {
  content: "";
}

.icon-mute:before {
  content: "";
}

.icon-steam:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-chart:before {
  content: "";
}

#loadingscreen.loaded {
  display: none !important;
}

body {
  color: #fbf1d7;
  background-color: #383530;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: TF2 Secondary;
  font-size: 2.5vh;
  position: fixed;
  overflow: hidden;
}

h1 {
  font-weight: normal;
}

a {
  color: #5e98d9;
  font-weight: bold;
  text-decoration: none;
}

nav {
  justify-content: space-between;
  width: 100%;
  height: 5%;
  margin: 1vh 0;
  display: flex;
  position: relative;
}

nav.bottom {
  position: absolute;
  bottom: 0;
}

nav.bottom .btn {
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
}

nav.top .btn {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 50vh;
  height: 100%;
  margin: 0 .5vh;
}

nav.top #soundbtn {
  margin: 0 .5vh;
}

nav .btn, nav .nosoundbtn {
  margin: 0 1vh;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

::-webkit-scrollbar-thumb:active {
  background: #646464;
}

::-webkit-scrollbar-button {
  display: none;
}

#aboutchangelog h2 {
  font-family: TF2 Build;
  font-size: 3.2vh;
}

#aboutchangelog section {
  margin-bottom: 8vh;
  font-family: TF2 Secondary;
}

#aboutchangelog section p {
  margin: 0 0 1vh;
}

#aboutcredits h1 {
  margin: 0 0 5vh;
  font-family: TF2 Build;
  font-size: 5vh;
}

#aboutcredits p {
  margin: 1vh 0;
  font-family: TF2 Secondary;
}

.creditsother {
  margin-top: 5vh;
}

#optionsscreen section {
  flex-wrap: wrap;
  max-height: 92%;
  display: flex;
  overflow-y: auto;
}

#optionsscreen section > div {
  border-bottom: .25vh solid #776b5f;
  flex-direction: column;
  flex-basis: 50%;
  display: flex;
}

#optionsscreen section > div:nth-of-type(odd) {
  border-right: .25vh solid #776b5f;
}

#optionsscreen section > div:last-of-type {
  border-bottom: none;
  flex-basis: 100%;
}

#optionsscreen section > div > .optiontitle {
  text-align: center;
  font-family: TF2 Build;
  font-size: 2.5vh;
  display: block;
}

#optionsscreen section > div > p {
  text-align: center;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 3vh;
  padding: 0 2%;
  font-family: TF2 Secondary;
  font-size: 2vh;
}

#optionsscreen section > div > p:last-child {
  margin-top: auto;
}

.btngroup {
  text-align: center;
  justify-content: space-evenly;
  height: 5vh;
  margin-bottom: 2vh;
  padding: 0 1vh;
  font-family: TF2 Build;
  display: flex;
}

.btngroup.checkboxgroup {
  grid-template-rows: 6vh;
  grid-template-columns: 6vh auto;
  grid-auto-rows: 6vh;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 2.2vh;
  display: grid;
}

.btngroup.checkboxgroup.col2 {
  grid-template-columns: 6vh auto 6vh auto;
}

.btngroup.checkboxgroup .checkbox {
  cursor: pointer;
  background-color: #211e1e;
  border: .25vh solid #776b5f;
  justify-content: center;
  align-items: center;
  width: 4.5vh;
  height: 4.5vh;
  margin-right: 1vh;
  font-size: 0;
  display: flex;
}

.btngroup.checkboxgroup .checkbox.checked {
  font-size: 3vh;
}

.btngroup.checkboxgroup p {
  text-align: left;
  margin: 0 2vh 0 0;
}

.btngroup.checkboxgroup select {
  height: 4.5vh;
  margin-left: 1vh;
}

.btngroup.checkboxgroup span {
  align-items: center;
  display: flex;
}

.btngroup > .btn {
  flex-basis: 25%;
  position: static;
}

.btngroup > .btn#resetbtn {
  flex-basis: 33%;
}

.btngroup select {
  text-align: center;
  text-align-last: center;
  color: #fbf1d7;
  background-color: #776b5f;
  border-style: solid;
  border-color: #776b5f;
  border-radius: 1.5vh;
  width: 70%;
  font-family: TF2 Build;
  font-size: 2vh;
  left: 15%;
}

#crateselectscreen, #countdownscreen, #resultscreen, #statsscreen, #aboutscreen, #optionsscreen, #bulkunboxselect {
  width: 100%;
  height: 100%;
}

#countdownscreen, #bulkunboxselect {
  z-index: 1000;
  background-color: #0005;
  position: absolute;
  top: 0;
  left: 0;
}

#countdownscreen input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#countdownscreen input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#bulkunboxselect input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#bulkunboxselect input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#countdownscreen input[type="number"], #bulkunboxselect input[type="number"] {
  -moz-appearance: textfield;
}

#aboutscreen {
  text-align: center;
}

#countdown, #bulkunboxcontainer, #bulkunboxprogress {
  text-align: center;
  color: #b85345;
  background-color: #292526;
  border: .5vh solid #554d4a;
  border-radius: 2.5vh;
  flex-direction: column;
  justify-content: center;
  min-width: 45vh;
  max-width: 90vw;
  height: 30vh;
  display: flex;
  position: absolute;
  top: 35vh;
  left: 50%;
  transform: translateX(-50%);
}

#countdown #uncratingtext, #bulkunboxcontainer #uncratingtext, #bulkunboxprogress #uncratingtext {
  margin-bottom: 2vh;
}

#bulkunboxcontainer, #bulkunboxprogress {
  color: #fbf1d7;
  height: auto;
  padding: 1vh;
}

#bulkunboxcontainer input, #bulkunboxprogress input {
  color: #fbf1d7;
  text-align: center;
  background-color: #211e1e;
  border-style: solid;
  border-color: #776b5f;
  margin-bottom: 2vh;
  padding: .75vh 0;
  font-family: TF2 Build;
  font-size: 2.5vh;
}

#bulkunboxcontainer .btn, #bulkunboxprogress .btn {
  flex-grow: 1;
  flex-basis: 0;
  height: 5vh;
}

#bulkunboxcontainer progress, #bulkunboxprogress progress {
  -webkit-appearance: none;
  border: 2px solid #776b5f;
  border-radius: 2.5vh;
  width: 100%;
  height: 3vh;
  overflow: hidden;
}

#bulkunboxcontainer progress::-webkit-progress-bar {
  background-color: #211e1e;
  border-radius: 2.5vh;
}

#bulkunboxprogress progress::-webkit-progress-bar {
  background-color: #211e1e;
  border-radius: 2.5vh;
}

#bulkunboxcontainer progress::-webkit-progress-value {
  background-color: #fbf1d7;
}

#bulkunboxprogress progress::-webkit-progress-value {
  background-color: #fbf1d7;
}

#resultscreen nav {
  position: absolute;
  bottom: 0;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  text-align: center;
  color: #fbf1d7;
  white-space: nowrap;
  pointer-events: none;
  z-index: 100;
  background-image: linear-gradient(#534a42, #373128);
  border-radius: .5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh 2vh;
  font-family: TF2 Build;
  font-size: 2.5vh;
  font-weight: normal;
  display: none;
  position: absolute;
}

[data-tooltip].tooltiptopleft:after {
  bottom: 90%;
  right: 90%;
}

[data-tooltip].tooltiptop:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

[data-tooltip].tooltiptopright:after {
  bottom: 90%;
  left: 90%;
}

[data-tooltip].tooltipleft:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

[data-tooltip].tooltipbottomleft:after {
  top: 90%;
  right: 90%;
}

[data-tooltip].tooltipbottomright:after {
  top: 90%;
  left: 90%;
}

[data-tooltip]:hover:after {
  display: flex;
}

.btn, .nosoundbtn, .btndisabled {
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  background-color: #776b5f;
  border-radius: 1.5vh;
  justify-content: center;
  align-items: center;
  font-family: TF2 Build;
  font-size: 2.2vh;
  display: flex;
  position: absolute;
}

a.btn {
  color: #fbf1d7;
  font-weight: normal;
  text-decoration: none;
}

.btn i, .nosoundbtn i, .btndisabled i {
  font-size: 3.8vh;
}

.btn i.icon-backpacktf, .nosoundbtn i.icon-backpacktf, .btndisabled i.icon-backpacktf {
  font-size: 3vh;
}

.btn [data-string] {
  display: none;
}

[data-string="160"] {
  white-space: nowrap;
}

.navbtn {
  z-index: 1;
  width: 5vh;
  height: 40%;
  font-size: 8vh;
  top: 30%;
}

#cratewindow > .btn {
  z-index: 2;
  width: 5vh;
  height: 8vh;
  right: -2.75vh;
}

#gridviewbtn {
  top: 6%;
}

#gridviewbtn > i {
  font-size: 6vh;
}

#randomcratebtn {
  top: 18%;
}

#searchbtn {
  top: 30%;
}

#previouscratebtn {
  left: -2.75vh;
}

#nextcratebtn {
  right: -2.75vh;
}

.btn:hover, .nosoundbtn:hover {
  background-color: #91493b;
}

.btn:active, .nosoundbtn:active {
  background-color: #81392b;
}

#unboxbtn, #randomcratebtn {
  flex-grow: 1;
  height: 100%;
}

.btndisabled {
  color: #222;
  background-color: #534a42;
}

.btndisabled:hover, .btndisabled:active {
  cursor: initial;
  background-color: #534a42;
}

.btndisabled i {
  color: #222;
}

#steambtn, #soundbtn {
  color: #fbf1d7;
  width: 8vh;
  height: 100%;
}

#crateimagediv {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

#crateinfotext {
  text-align: center;
  font-family: TF2 Secondary;
}

#countdown > p {
  margin: 0;
  font-family: TF2 Build;
  font-size: 3vh;
}

#countdown > p:last-child {
  font-size: 6vh;
}

.tooltip {
  text-align: center;
  z-index: 10;
  background-image: linear-gradient(#534a42, #373128);
  border-radius: .5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6vh;
  padding: 0 2vh;
  font-family: TF2 Build;
  font-size: 2.5vh;
  display: none;
  position: absolute;
}

#steamtooltip {
  top: 90%;
  left: 7vh;
}

#soundtooltip {
  top: 90%;
  right: 7vh;
}

#steama:hover + .tooltip, #soundbtn:hover + .tooltip {
  display: flex;
}

#crateimagediv img {
  object-fit: contain;
  max-width: 100vw;
  height: 70%;
  max-height: 70%;
}

#crategridsearch {
  flex-wrap: none;
  border-bottom: .5vh solid #554d4a;
  height: 6%;
  display: none;
}

#crategridsearch .crategridsearchicon {
  border-right: .5vh solid #554d4a;
  justify-content: center;
  align-items: center;
  width: 6vh;
  display: flex;
}

#crategridsearch .crategridsearchicon .icon-search {
  font-size: 2.8vh;
}

#crategridsearchinput {
  color: #fbf1d7;
  background: none;
  border: none;
  flex-grow: 1;
  flex-basis: 10%;
  padding: 0 1vh;
  font-family: TF2 Build;
  font-size: 2.5vh;
}

#cratewindow {
  background-color: #292526;
  border: .5vh solid #554d4a;
  border-radius: 2.5vh;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 6vh);
  height: 84%;
  display: flex;
  position: absolute;
  top: 8%;
  left: 3vh;
}

#cratewindow.showsearch.gridactive #crategrid {
  flex-basis: 93%;
}

#cratewindow.showsearch.gridactive #crategridsearch {
  display: flex;
}

#crateview {
  width: 100%;
  height: 100%;
  position: relative;
}

#crategrid {
  grid-gap: 1% 1%;
  grid-template-rows: 32.5%;
  grid-template-columns: repeat(auto-fill, minmax(22vh, 1fr));
  grid-auto-rows: 32.5%;
  height: 100%;
  margin-right: 7vh;
  padding: 1vh .5vh 0 1vh;
  overflow: hidden auto;
}

#cratemain {
  float: left;
  width: 49%;
  height: 100%;
  position: relative;
}

#crateinfo {
  width: 100%;
  height: 100%;
}

#cratedetails {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
}

#cratename {
  color: gold;
  text-align: center;
  margin: 0;
  padding: 1vh .5vh 0;
  font-family: TF2 Build;
  font-size: 3vh;
}

#crateseries {
  color: gold;
  text-align: center;
  margin: 0;
  font-family: TF2 Build;
  font-size: 1.8vh;
}

#crateresult > p {
  margin: 1vh 0 0;
}

#newlootacquired {
  text-align: center;
  margin: 1vh;
  font-family: TF2 Build;
  font-size: 4.2vh;
  line-height: 4vh;
  overflow: hidden;
}

#youtext {
  text-align: center;
  white-space: nowrap;
  margin: 0;
  font-family: TF2 Build;
  font-size: 2.75vh;
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
}

#unboxedtext {
  color: #b85345;
}

#unboxeditem {
  background-color: #332f2e;
  border: .5vh solid #554d4a;
  border-radius: 2.5vh;
  width: 85vw;
  height: 80%;
  position: absolute;
  bottom: 7%;
  left: 7.5vw;
}

#crateinfobtn {
  width: calc(100% - 22vh);
  height: 5vh;
  display: flex;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
}

#crateinforeturnbtn {
  width: 40%;
  height: 5vh;
  bottom: 1%;
  left: 7.5%;
}

#crateinfoeffectsbtn, #crateinfolootbtn {
  width: 40%;
  height: 5vh;
  bottom: 1%;
  right: 7.5%;
}

.tabreturnbtn {
  width: 98%;
  height: 5%;
  bottom: 0;
  left: 1%;
}

#returnbtn {
  width: 47%;
  left: 1%;
}

#unboxagainbtn {
  width: 47%;
  right: 1%;
}

#lootimgdiv {
  height: 100%;
  position: relative;
}

#lootimg, #effectimg {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#effectimg {
  transform: translate(-50%, -65%);
}

#kitimg {
  z-index: 1;
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translateX(-65%);
  width: auto !important;
  height: 16% !important;
}

.kitimg {
  z-index: 1;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translateX(-63%);
  width: auto !important;
  height: 32% !important;
}

#kitfield {
  text-align: center;
  font-family: TF2 Secondary;
  font-size: 2.4vh;
}

#lootimg {
  z-index: 1;
}

#lootimgdiv img {
  object-fit: contain;
  width: 100%;
  height: 50%;
  display: block;
}

#loottextdiv {
  width: 98%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 1%;
}

#lootname {
  display: block;
}

#lootnamediv {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  font-family: TF2 Build;
  font-size: 3vh;
  position: relative;
  top: 3%;
}

#lootbonusandstats {
  z-index: 11;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 35%;
  display: flex;
  position: absolute;
  bottom: 1%;
  left: 0;
}

#lootbonusandstats > div {
  height: 100%;
}

#lootbonusandstats #lootbonusitems {
  flex-grow: 1;
  height: 50%;
}

#lootbonusandstats #lootbonusitems #lootbonusitemheader {
  text-align: center;
  height: 25%;
  font-family: TF2 Secondary;
  font-size: 2.75vh;
}

#lootbonusandstats #lootbonusitems #lootbonusitemheader > p {
  margin: 0;
}

#lootbonusandstats #lootbonusitems #lootbonusnone {
  justify-content: center;
  font-family: TF2 Build;
  font-size: 3.5vh;
  display: flex;
}

#lootbonusandstats #lootstatscontainer {
  flex-direction: row;
  height: 15%;
}

#lootmarketbtns {
  z-index: 10;
  position: absolute;
  top: 40%;
  right: .5vh;
  transform: translateY(-50%);
}

#lootmarketbtns a {
  width: 6vh;
  height: 6vh;
  margin: 1vh 0;
  position: relative;
}

#lootmarketbtns a:first-child {
  margin-top: 0;
}

#lootmarketbtns [data-tooltip]:after {
  white-space: normal;
  min-width: 20vh;
}

#lootbonusitems {
  text-align: center;
  font-family: TF2 Build;
  font-size: 3vh;
  line-height: 3vh;
}

#lootbonusitems #lootbonusitemcontainer {
  justify-content: space-evenly;
  height: 70%;
  display: flex;
}

#lootbonusitems #lootbonusitemcontainer > div {
  background-color: #292526;
  border: .2vh solid gold;
  border-radius: 2.5vh;
  width: 15vh;
  max-width: 33vw;
  max-height: 15vh;
  display: inline-block;
  position: relative;
}

#lootbonusitems #lootbonusitemcontainer > div.unusual {
  border-color: #8650ac;
}

#lootbonusitems #lootbonusitemcontainer > div img {
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}

#lootbonusitems #lootbonusitemcontainer > div img.unusualifierimg {
  height: 39%;
  position: absolute;
  top: 49%;
  left: 35%;
}

.bonustooltip {
  white-space: nowrap;
  color: gold;
  z-index: 1;
  background-image: linear-gradient(#534a42, #373128);
  border-radius: .5vh;
  max-width: 100vw;
  padding: 1vh 4vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

#lootbonusitemcontainer div:hover .bonustooltip {
  display: block;
}

#lootstatscontainer {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

#lootstatscontainer div {
  text-align: center;
  font-family: TF2 Build;
  font-size: 4.25vh;
}

#lootstatscontainer div [data-string] {
  vertical-align: middle;
  font-family: TF2 Secondary;
  font-size: 2.25vh;
  line-height: 3vh;
  display: inline-block;
}

#statscontainer > div, #aboutcontainer > div {
  height: 100%;
  padding: 0 1vh;
  display: none;
  overflow-y: auto;
}

div.visible {
  display: block !important;
}

#statscontainer, #aboutcontainer {
  height: 86%;
  font-family: TF2 Secondary;
}

#aboutdonations {
  font-size: 2vh;
  line-height: 2.8vh;
}

#aboutdonations h2, #aboutdonations h3 {
  font-size: 2.75vh;
}

#aboutdonations ol {
  margin-top: 0;
  padding: 0;
  font-size: 2.5vh;
  list-style-position: inside;
  display: inline-block;
}

#aboutdonations ol li {
  margin: .5vh 0;
}

#aboutchangelog {
  font-size: 2vh;
}

#statscontainerinner {
  text-align: center;
  white-space: nowrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

#statscontainerinner > nav {
  width: 60%;
  height: auto;
  margin: 0 auto;
  font-family: TF2 Build;
}

#statscontainerinner > nav > div {
  align-items: center;
  display: flex;
}

#statscontainerinner > nav > .btn {
  width: 15vh;
  margin: 0;
  font-size: 4.5vh;
}

#statscontainerinner > div {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1vh;
  font-size: 2.5vh;
  display: none;
  overflow: auto;
}

#statscontainerinner > div #statsluckcontainer {
  white-space: normal;
}

#statscontainerinner > div #statsluckcontainer p {
  margin-top: 0;
  font-size: 2.5vh;
}

#statscontainerinner > div.statvisible {
  display: flex !important;
}

#statscontainerinner table {
  white-space: normal;
  border-spacing: 0 1vh;
}

#statscontainerinner table td {
  padding-right: 1vh;
}

#statscontainerinner table td:first-child {
  text-align: left;
}

#statscontainerinner.visible {
  display: flex !important;
}

#statscontainerinner #unusualluck, #statscontainerinner #strangeluck {
  font-family: TF2 Build;
  font-size: 3.2vh !important;
}

#statscontainerinner #unusualluck.lucky, #statscontainerinner #strangeluck.lucky {
  color: #22cb22;
}

#statscontainerinner #unusualluck.unlucky, #statscontainerinner #strangeluck.unlucky {
  color: #cb2222;
}

#statscontainerinner #unusualluck.neither, #statscontainerinner #strangeluck.neither {
  color: gold;
}

#statscontainerinner #unusualluckdesc, #statscontainerinner #strangeluckdesc {
  line-height: 3vh;
}

#statscontainerinner #unusualluckdesc#unusualluckdesc, #statscontainerinner #strangeluckdesc#unusualluckdesc {
  margin-bottom: 5vh;
}

#statscontainerinner #unusualluckdesc#strangeluckdesc, #statscontainerinner #strangeluckdesc#strangeluckdesc {
  margin-bottom: 0;
}

#statscontainerinner > div > div {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#statsluckcontainer {
  flex-direction: column;
  display: none;
}

#statslucknotenough {
  white-space: normal;
  font-size: 3.2vh;
  display: none;
}

#nounusuals, #noitems {
  text-align: center;
  width: 100%;
  margin: 0;
  font-family: TF2 Secondary;
  font-size: calc(3.5vh + .75vw);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#statscrates {
  grid-gap: 1% 1%;
  grid-template-rows: 32.5%;
  grid-template-columns: repeat(auto-fill, minmax(22vh, 1fr));
  grid-auto-rows: 32.5%;
  height: 100%;
  padding: 0 .5vw;
  overflow: hidden auto;
}

#statscrates.visible {
  display: grid !important;
}

#statsunusualscontent, #bulkresultsunusualscontent {
  grid-gap: 1% 1%;
  grid-template-rows: 49%;
  grid-template-columns: repeat(auto-fill, minmax(25vh, 1fr));
  grid-auto-rows: 49%;
  height: 100%;
  padding: 0 .5vw;
  display: grid;
}

#statsunusualscontent > div, #bulkresultsunusualscontent > div {
  text-align: center;
  background-color: #292526;
  border: .2vh solid #8650ac;
  border-radius: 2.5vh;
  font-size: 2vh;
  position: relative;
}

#statscratesdetails {
  flex-direction: column;
  height: 100%;
  display: none;
}

#statscratesdetails.visible {
  display: flex;
}

#statscratesdetails > div {
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: hidden auto;
}

#statscratesdetails #cratedetailsheader {
  text-align: center;
  color: gold;
  background: repeating-linear-gradient(-60deg, #282320, #282320 .6vh, #2e2926 .6vh 1.8vh);
  border-bottom: .5vh solid #615c59;
  justify-content: space-between;
  height: 15%;
  margin-bottom: 1vh;
  font-family: TF2 Build;
  display: flex;
  position: relative;
}

#statscratesdetails #cratedetailsheader > img {
  object-fit: contain;
  object-position: left;
  flex-basis: 30%;
  height: 100%;
  margin: 0 1vh;
}

#statscratesdetails #cratedetailsheader > div {
  flex-direction: column;
  flex-basis: 40%;
  justify-content: center;
  margin: 0 1vh;
  display: flex;
}

#statscratesdetails #cratedetailsheader > div > p {
  margin: 0;
}

#statscratesdetails #cratedetailsheader > div#cratedetailsstats {
  flex-basis: 30%;
}

#statscratesdetails nav .btn {
  width: 98%;
  margin: 0;
  left: 1%;
}

#statscratesdetailscontent, #bulkresultsitems, #bulkresultsbonusitems {
  grid-gap: 1vh 1vh;
  color: gold;
  border-color: gold;
  flex-grow: 1;
  grid-template-rows: 42%;
  grid-template-columns: repeat(auto-fill, minmax(23vh, 1fr));
  grid-auto-rows: 42%;
  max-height: 83%;
  padding: 0 .5vw;
  display: grid;
}

#statscratesdetailscontent.col1, #bulkresultsitems.col1, #bulkresultsbonusitems.col1 {
  grid-template-rows: 48%;
  grid-auto-rows: 48%;
}

#statscratesdetailscontent.col2, #bulkresultsitems.col2, #bulkresultsbonusitems.col2 {
  grid-template-columns: repeat(auto-fill, minmax(45vh, 1fr));
}

#statscratesdetailscontent.col3, #bulkresultsitems.col3, #bulkresultsbonusitems.col3 {
  grid-template-rows: 52%;
  grid-template-columns: repeat(auto-fill, minmax(61vh, 1fr));
  grid-auto-rows: 52%;
}

#statscratesdetailscontent > div, #bulkresultsitems > div, #bulkresultsbonusitems > div {
  text-align: center;
  background-color: #292526;
  border: .2vh solid;
  border-radius: 2.5vh;
  font-size: 2vh;
  position: relative;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom), #bulkresultsitems > div > div:not(.cratedetailsitembottom), #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) {
  height: 90%;
  display: flex;
  position: relative;
  top: 5%;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom) > img, #bulkresultsitems > div > div:not(.cratedetailsitembottom) > img, #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) > img {
  object-fit: contain;
  flex-grow: 1;
  max-width: 45%;
  height: 90%;
  position: relative;
  top: 5%;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist, #bulkresultsitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist, #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist:nth-child(3), #bulkresultsitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist:nth-child(3), #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist:nth-child(3) {
  margin-left: .5vh;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p, #bulkresultsitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p, #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p {
  margin: .25vh 0;
  font-family: TF2 Build;
  font-size: 1.9vh;
}

#statscratesdetailscontent > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p:nth-child(2n), #bulkresultsitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p:nth-child(2n), #bulkresultsbonusitems > div > div:not(.cratedetailsitembottom) .cratedetailsqualitylist > p:nth-child(2n) {
  margin-bottom: .75vh;
}

#statscratesdetailscontent > div .cratedetailsitembottom, #bulkresultsitems > div .cratedetailsitembottom, #bulkresultsbonusitems > div .cratedetailsitembottom {
  width: 100%;
  position: absolute;
  bottom: 1%;
}

#statscratesdetailscontent > div .cratedetailsitembottom .cratedetailsitemmarketbtns, #bulkresultsitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns, #bulkresultsbonusitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns {
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

#statscratesdetailscontent > div .cratedetailsitembottom .cratedetailsitemmarketbtns a, #bulkresultsitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns a, #bulkresultsbonusitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns a {
  width: 30%;
  height: 4vh;
  position: relative;
}

#statscratesdetailscontent > div .cratedetailsitembottom .cratedetailsitemmarketbtns a i, #bulkresultsitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns a i, #bulkresultsbonusitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns a i {
  font-size: 3vh;
}

#statscratesdetailscontent > div .cratedetailsitembottom .cratedetailsitemmarketbtns [data-tooltip]:after, #bulkresultsitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns [data-tooltip]:after, #bulkresultsbonusitems > div .cratedetailsitembottom .cratedetailsitemmarketbtns [data-tooltip]:after {
  white-space: normal;
  min-width: 20vh;
}

#statscratesdetailscontent > div > p, #bulkresultsitems > div > p, #bulkresultsbonusitems > div > p {
  z-index: 1;
  text-shadow: .1vh .1vh #000;
  width: 100%;
  margin: 0;
  padding: 0 1vw;
  font-family: TF2 Build;
  position: absolute;
}

#statscratesdetailscontent > div .cratedetailsqualitylist, #bulkresultsitems > div .cratedetailsqualitylist, #bulkresultsbonusitems > div .cratedetailsqualitylist {
  color: #fbf1d7;
}

#statscratesdetailscontent > div .cratedetailsitemunboxed, #bulkresultsitems > div .cratedetailsitemunboxed, #bulkresultsbonusitems > div .cratedetailsitemunboxed {
  margin: 0 0 .5vh;
  font-family: TF2 Build;
}

#statscratesdetailscontent > div .cratedetailsitemunboxed.cratedetailsitemnomarket, #bulkresultsitems > div .cratedetailsitemunboxed.cratedetailsitemnomarket, #bulkresultsbonusitems > div .cratedetailsitemunboxed.cratedetailsitemnomarket {
  margin: 0;
  bottom: 0;
}

#statscratesdetailscontent > div .cratedetailskillstreak, #bulkresultsitems > div .cratedetailskillstreak, #bulkresultsbonusitems > div .cratedetailskillstreak {
  color: #7ea9d1;
  font-family: TF2 Secondary;
  font-size: 1.8vh;
}

#statscrates > div, #crategrid div {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: #292526;
  border: .2vh solid gold;
  border-radius: 2.5vh;
  font-size: 2vh;
  position: relative;
}

#statscrates > div:last-child, #crategrid div:last-child {
  margin-bottom: 1px;
}

#statsunusualscontent > div p, #bulkresultsunusualscontent > div p {
  color: #8650ac;
  z-index: 1;
  width: 100%;
  padding: 0 1vw;
  font-family: TF2 Build;
}

#statsunusualscontent > div > p, #bulkresultsunusualscontent > div > p {
  margin-top: 1vh;
}

.statsunusualsbottom {
  width: 100%;
  position: absolute;
  bottom: 1%;
}

.statsunusualsmarketbtns {
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

.statsunusualsmarketbtns a {
  width: 30%;
  height: 4vh;
  position: relative;
}

.statsunusualsmarketbtns a i {
  font-size: 3vh;
}

.statsunusualsmarketbtns [data-tooltip]:after {
  white-space: normal;
  min-width: 20vh;
}

#statscrates > div:hover, #crategrid div:hover {
  background-color: #927600 !important;
}

.statscratestext {
  height: 30%;
}

#statsunusualscontent > div > img, #bulkresultsunusualscontent > div > img, #statscratesdetailscontent > div > img, #bulkresultsitems > div > img, #bulkresultsbonusitems > div > img {
  object-fit: contain;
  z-index: 0;
  width: 100%;
  height: 55%;
  position: absolute;
  top: 25%;
  left: 0;
}

#statsunusualscontent > div > img.cratedetailskitimg, #bulkresultsunusualscontent > div > img.cratedetailskitimg, #statscratesdetailscontent > div > img.cratedetailskitimg, #bulkresultsitems > div > img.cratedetailskitimg, #bulkresultsbonusitems > div > img.cratedetailskitimg {
  width: auto;
  height: 15%;
  top: 50%;
  left: 50%;
  transform: translateX(-66%);
}

#statsunusualscontent > div > img.unusualifierimg, #bulkresultsunusualscontent > div > img.unusualifierimg, #statscratesdetailscontent > div > img.unusualifierimg, #bulkresultsitems > div > img.unusualifierimg, #bulkresultsbonusitems > div > img.unusualifierimg {
  width: auto;
  height: 24%;
  top: 51%;
  left: 50%;
  transform: translateX(-40%);
}

#statsunusualscontent > div > img.effectimg, #bulkresultsunusualscontent > div > img.effectimg, #statscratesdetailscontent > div > img.effectimg, #bulkresultsitems > div > img.effectimg, #bulkresultsbonusitems > div > img.effectimg {
  height: 60%;
  top: 20%;
}

#statscrates > div > img, #crategrid div img {
  object-fit: contain;
  z-index: 0;
  width: 100%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 0;
}

.statscratesname, .statscratesseries, .statsitemname, .statsitemunbox {
  z-index: 1;
  text-shadow: .1vh .1vh #000;
  width: 100%;
  padding: 0 1vw;
  font-family: TF2 Build;
  position: absolute;
}

.statscratesname, .statsitemname {
  color: gold;
  margin-top: .5vh;
}

.statscratesseries, .statsitemunbox {
  color: gold;
  margin-bottom: .5vh;
  bottom: 0;
}

.statsunusualsname {
  margin-top: 1vh;
}

.statsunusualseffect {
  margin-bottom: 1vh;
  bottom: 0;
}

.break {
  display: none;
}

.active {
  background-color: #bd4a37 !important;
}

#cratelootdiv, #crateeffectsdiv {
  text-align: center;
  width: 100%;
  max-height: 80%;
  font-family: TF2 Secondary;
  font-size: 2vh;
  position: absolute;
  top: 50%;
  overflow: auto;
  transform: translateY(-50%);
}

#cratelootlist, #crateeffectslist, #cratelootbonus ul {
  padding-left: 0;
  line-height: 3vh;
  list-style: none;
}

#cratelootlist {
  color: #8f8a83;
}

#crateeffectslist, #cratelootbonus ul {
  color: gold;
}

#cratenote {
  margin-bottom: 0;
}

.column {
  column-count: 2;
}

#crateloottitle {
  margin-top: 0;
}

#effectname, #effecttext {
  text-align: center;
  color: #8650ac;
  font-family: TF2 Secondary;
  font-size: 2.4vh;
}

.unusualloot, #kitfield {
  color: #7ea9d1;
}

#exitgridviewbtn {
  top: 6%;
}

#statsunusuals {
  overflow-x: hidden;
}

#statsunusuals nav {
  align-items: center;
  max-width: 80vh;
  height: 5vh;
  margin-left: auto;
  margin-right: auto;
}

#statsunusuals nav div {
  text-align: center;
  margin: 0 2vh;
}

#statsunusuals nav .btn {
  flex-grow: 1;
  max-width: 20vh;
  height: 100%;
  margin: 0 1vh;
  font-size: 4vh;
}

#bulkunboxresults {
  width: 100%;
  height: 100%;
}

#bulkunboxresults > div {
  flex-direction: column;
  width: 100%;
  height: 93%;
  display: flex;
  overflow: hidden auto;
}

#bulkunboxresults > div nav {
  align-items: center;
  max-width: 80vh;
  height: 5vh;
  min-height: 5vh;
  margin-left: auto;
  margin-right: auto;
}

#bulkunboxresults > div nav div {
  text-align: center;
  margin: 0 2vh;
}

#bulkunboxresults > div nav .btn {
  flex-grow: 1;
  max-width: 20vh;
  height: 100%;
  margin: 0 1vh;
  font-size: 4vh;
}

#bulkunboxresults [data-statstring] {
  padding: 0 1vh;
  display: none;
}

#bulkunboxresults [data-statstring].bulkvisible {
  display: flex;
}

#bulkunboxresults [data-statstring].bulkvisible#bulkresultsitems, #bulkunboxresults [data-statstring].bulkvisible#bulkresultsbonusitems {
  display: grid;
}

#bulkunboxresults #bulkresultsunusuals {
  flex-direction: column;
  height: 100%;
}

#bulkunboxresults > nav {
  justify-content: center;
  margin-top: 1vh;
}

#bulkunboxresults > nav > .btn {
  width: 100%;
  max-width: 66vh;
}

#bulkresultsstats {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#bulkresultsstats table {
  margin: 1vh 0;
}

#bulkresultsheader {
  text-align: center;
  color: gold;
  text-align: center;
  background: repeating-linear-gradient(-60deg, #282320, #282320 .6vh, #2e2926 .6vh 1.8vh);
  border-bottom: .5vh solid #615c59;
  padding: 1vh 0;
  font-family: TF2 Build;
}

#bulkresultsheader p {
  margin: 0;
}

#bulkresultsheader #bulkresultstitle {
  margin-top: 2vh;
}

[data-unusualmaxpage="1"], [data-unusualmaxpage="0"] {
  display: none;
}

@media (orientation: portrait) and (aspect-ratio <= 6 / 10) {
  #lootstatscontainer {
    height: 40% !important;
  }

  #lootstatscontainer div span {
    display: block;
  }

  .btngroup.col2 {
    grid-template-columns: 6vh auto !important;
  }

  .btngroup.col2 [data-option]:nth-of-type(2n), .btngroup.col2 [data-optionvalue]:nth-of-type(2n), .btngroup.col2 [data-option]:nth-of-type(2n) + p, .btngroup.col2 [data-option]:nth-of-type(2n) + span, .btngroup.col2 [data-optionvalue]:nth-of-type(2n) + p, .btngroup.col2 [data-optionvalue]:nth-of-type(2n) + span {
    order: 1;
  }
}

@media (orientation: portrait) and (aspect-ratio <= 7 / 10) {
  #statscratesdetailscontent.col2, #bulkresultsitems.col2, #bulkresultsbonusitems.col2 {
    grid-template-columns: 100% !important;
  }

  #statscratesdetailscontent.col3, #bulkresultsitems.col3, #bulkresultsbonusitems.col3 {
    grid-template-rows: 60% !important;
    grid-template-columns: 100% !important;
    grid-auto-rows: 60% !important;
  }

  #statscratesdetailscontent#bulkresultsitems.col3, #bulkresultsitems#bulkresultsitems.col3, #bulkresultsbonusitems#bulkresultsitems.col3 {
    grid-template-rows: 63% !important;
    grid-auto-rows: 63% !important;
  }

  [data-tooltip].tooltipmobiletopleft:after {
    top: initial;
    right: initial;
    bottom: 90%;
    right: 90%;
    transform: none;
  }

  [data-tooltip].tooltipmobiletopright:after {
    top: initial;
    right: initial;
    bottom: 90%;
    left: 90%;
    transform: none;
  }

  [data-tooltip].tooltipmobiletop:after {
    top: initial;
    right: initial;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  [data-tooltip].tooltipmobileleft:after {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }

  [data-tooltip].tooltipmobileright:after {
    top: 50%;
    left: 100%;
    right: initial;
    transform: translateY(-50%);
  }

  [data-tooltip].tooltipmobilebottomleft:after {
    top: 90%;
    right: 90%;
  }

  [data-tooltip].tooltipmobilebottomright:after {
    top: 90%;
    left: 90%;
  }

  .gridactive [data-tooltip].tooltipmobilegridtop:after {
    top: initial;
    right: initial;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  #crateselectscreen nav.top .btn, #crateselectscreen nav.top #soundbtn {
    margin: 0 .25vh;
  }

  #optionsscreen > div > span {
    width: 100%;
    height: initial;
    margin-bottom: 1vh;
  }

  #cratelootdiv, #crateeffectsdiv {
    max-height: 71%;
    padding: 0 1vh;
    width: 100% !important;
    top: 48% !important;
    left: 0 !important;
  }

  #cratedetails {
    width: 100% !important;
    left: 0 !important;
  }

  #crateinforeturnbtn {
    width: calc(50% - 12vh);
    height: 5vh;
    bottom: 1%;
    left: 11vh;
  }

  #crateinfoeffectsbtn, #crateinfolootbtn {
    width: calc(50% - 12vh);
    height: 5vh;
    bottom: 1%;
    right: 11vh;
  }

  #cratewindow:not(.gridactive) #gridviewbtn {
    top: initial;
    bottom: 1%;
    left: 1vh;
    right: initial;
    width: 8vh;
    height: 5vh;
  }

  #cratewindow:not(.gridactive) #randomcratebtn, #cratewindow:not(.gridactive) #searchbtn {
    top: initial;
    bottom: 1%;
    left: initial;
    width: 8vh;
    height: 5vh;
    right: 1vh;
  }

  #cratewindow:not(.gridactive) #previouscratebtn {
    top: initial;
    width: 45%;
    height: 5vh;
    font-size: 6vh;
    bottom: 7vh;
    left: 1vh;
  }

  #cratewindow:not(.gridactive) #nextcratebtn {
    top: initial;
    width: 45%;
    height: 5vh;
    font-size: 6vh;
    bottom: 7vh;
    right: 1vh;
  }

  #cratewindow.gridactive {
    padding-bottom: 6.5vh;
  }

  #cratewindow.gridactive > .btn {
    width: 30%;
    height: 5vh;
  }

  #cratewindow.gridactive #exitgridviewbtn {
    top: initial;
    width: 26.66%;
    bottom: .75vh;
    left: 5%;
  }

  #cratewindow.gridactive #randomcratebtn {
    top: initial;
    width: 26.66%;
    bottom: .75vh;
    left: 36.66%;
  }

  #cratewindow.gridactive #searchbtn {
    top: initial;
    width: 26.66%;
    bottom: .75vh;
    right: 5%;
  }

  #crategrid {
    margin-right: 0;
    padding: 0 1vh 1vh;
  }

  .btngroup > .btn {
    flex-basis: 35%;
  }

  .btngroup select {
    border-style: solid;
    width: 90%;
  }
}

@media (orientation: portrait) and (aspect-ratio <= 9 / 10) {
  #unboxeditem {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    width: 100%;
    padding: 0 1vh;
    left: 0;
  }
}

@media (orientation: portrait) {
  #optionsscreen section > div {
    flex-basis: 100%;
    border-right: none !important;
  }

  #optionsscreen section > div:nth-last-of-type(2) {
    border-bottom: .25vh solid #776b5f;
  }

  #cratedetailsheader {
    flex-direction: column;
    padding: 0 1vh;
    height: auto !important;
  }

  #cratedetailsheader > img {
    display: none;
  }

  #cratedetailsheader > div {
    margin: 1vh 0 !important;
  }

  #cratewindow {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    width: 100%;
    height: 86%;
    top: 7%;
    left: 0;
  }

  #cratewindow > .btn {
    right: .5vh;
  }

  #cratedetails {
    width: calc(100% - 8vh);
    left: 4vh;
  }

  #previouscratebtn {
    left: .5vh;
  }

  #nextcratebtn {
    right: .5vh;
  }

  #cratelootdiv, #crateeffectsdiv {
    width: calc(100% - 7vh);
    left: 3.5vh;
  }

  #statscontainerinner > nav {
    width: 98%;
  }

  .break {
    display: initial;
  }

  #cratemain {
    width: 100%;
  }

  #lootunboxchance {
    left: 5%;
  }

  #lootunboxedtimes {
    right: 5%;
  }

  #lootbonusitemcontainer .bonustooltip {
    min-width: 33vw;
  }

  #lootbonusitemcontainer.i2 .bonustooltip {
    min-width: 40vw;
  }
}

@media (orientation: landscape) {
  #lootstatscontainer > div {
    flex-basis: 50%;
  }

  #crategrid {
    margin-right: 3.5vh;
  }

  .btn [data-string] {
    display: inline;
  }

  nav .btn i {
    position: absolute;
    left: 1.5vh;
  }

  #steambtn i, #soundbtn i {
    position: static;
  }

  #cratelootdiv, #crateeffectsdiv {
    width: calc(100% - 7vh);
    left: 3.5vh;
  }

  #randomcratebtn {
    width: 50%;
  }

  #cratemain {
    width: 100%;
  }

  .tabreturnbtn, #statscratesdetails nav .btn {
    width: 60%;
    left: 20%;
  }

  #returnbtn {
    width: 40%;
    left: 5%;
  }

  #unboxagainbtn {
    width: 40%;
    right: 5%;
  }

  #lootunboxchance {
    left: 5%;
  }

  #lootunboxedtimes {
    right: 5%;
  }
}

@media (orientation: landscape) and (aspect-ratio >= 4 / 3) {
  #lootunboxchance, #lootunboxedtimes {
    width: 25%;
    bottom: 17.5%;
  }

  #lootunboxchance {
    left: initial;
  }

  #lootunboxedtimes {
    right: 0;
  }

  #lootmarketbtns {
    top: 45%;
  }

  #cratemain {
    width: 49%;
    display: block !important;
  }

  #crateinfobtn, #crateinforeturnbtn {
    display: none !important;
  }

  #lootimgdiv {
    float: left;
    width: 50%;
  }

  #lootimgdiv img {
    height: 60%;
  }

  #lootimgdiv #effectimg {
    height: 70%;
  }

  #lootimg, #effectimg {
    top: 50%;
  }

  #crateimagediv img {
    max-height: 90%;
  }

  #unboxeditem {
    width: 80%;
    height: 77%;
    bottom: 10%;
    left: 10%;
  }

  #loottextdiv {
    width: 50%;
    left: initial;
    right: 0%;
  }

  #lootnamediv {
    top: 10%;
  }

  #crateinfoeffectsbtn, #crateinfolootbtn {
    width: 70%;
    left: 15%;
  }

  #crateimagediv {
    width: 50%;
    left: 0;
    display: flex !important;
  }

  #cratedetails {
    width: 50%;
    right: 0;
    display: block !important;
  }

  #lootbonusandstats {
    height: 42%;
  }

  #lootstatscontainer {
    height: 28% !important;
  }

  #lootstatscontainer div span {
    display: block;
  }

  #kitimg {
    top: 47%;
    left: 50%;
    transform: translateX(-68%);
    height: 17% !important;
  }
}

@media (orientation: landscape) and (aspect-ratio >= 14 / 9) {
  #optionsscreen {
    width: 155vh;
    margin: 0 auto;
  }
}

.amoledtheme, .amoledtheme #cratewindow, .amoledtheme #crategrid div, .amoledtheme #statsunusualscontent > div, .amoledtheme #statscrates > div, .amoledtheme #unboxeditem, .amoledtheme #lootbonusitems #lootbonusitemcontainer > div, .amoledtheme #countdown, .amoledtheme #bulkunboxselect > div, .amoledtheme #bulkresultsunusualscontent > div, .amoledtheme #bulkresultsbonusitems > div, .amoledtheme #bulkresultsitems > div {
  background-color: #000;
}

.amoledtheme .checkbox {
  background-color: #000 !important;
}

.amoledtheme #cratedetailsheader, .amoledtheme #statscratesdetailscontent > div, .amoledtheme #bulkresultsheader {
  background-color: #000 !important;
  background-image: none !important;
}

.colorunique {
  color: gold;
}

.colorunique.bordercolor {
  border-color: gold;
}

.colorstrange, .colorstrange > .statsitemname {
  color: #cf6a32;
}

.colorstrange.bordercolor {
  border-color: #cf6a32;
}

.colorhaunted, .colorhaunted > .statsitemname {
  color: #38f3ab;
}

.colorhaunted.bordercolor {
  border-color: #38f3ab;
}

.colorcivilian {
  color: #b0c3d9;
}

.colorcivilian.bordercolor {
  border-color: #b0c3d9;
}

.colorfreelance {
  color: #5e98d9;
}

.colorfreelance.bordercolor {
  border-color: #5e98d9;
}

.colormercenary {
  color: #4b69ff;
}

.colormercenary.bordercolor {
  border-color: #4b69ff;
}

.colorcommando {
  color: #8847ff;
}

.colorcommando.bordercolor {
  border-color: #8847ff;
}

.colorassassin {
  color: #d32ce6;
}

.colorassassin.bordercolor {
  border-color: #d32ce6;
}

.colorelite {
  color: #eb4b4b;
}

.colorelite.bordercolor {
  border-color: #eb4b4b;
}

.colorunusual {
  color: #8650ac;
}

.colorunusual.bordercolor {
  border-color: #8650ac;
}

.colordecorated {
  color: #fafafa;
}
/*# sourceMappingURL=main.59c84d49.css.map */
